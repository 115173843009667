<template>
  <base-view title="Saldos por partidas presupuestales">
    <div class="container-fluid mt-4">
      <div class="card">
        <div class="card-body">
          <MomentoContable/>
        </div>
      </div>
    </div>
  </base-view>
</template>

<script>
import MomentoContable from '@/components/Saldos/MomentoContable'

export default {
  name: 'MomentoContableView',

  components: {
    MomentoContable
  }
}
</script>
